import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header({toggleLanguage, translateIcon, isCookieFreePage}) {

    return (
        <header className="header-container">
            <h1>SkanEat</h1>
            <nav style={{ display: isCookieFreePage ? 'none' : 'block' }}>
                <Link to="/" data-lang-key>Accueil</Link>
                <Link to="/features" data-lang-key>Fonctionnalités</Link>
                <Link to="/pricing" data-lang-key>Tarifs</Link>
                <Link to="/faq" data-lang-key>FAQ</Link>
                <Link to="/contact" data-lang-key>Contact</Link>
            </nav>
            <button onClick={toggleLanguage} className='langue' disabled={isCookieFreePage}>
                <img src={translateIcon} alt="Translate" style={{ width: '30px', height: '30px' }} />
            </button>
        </header>
    );
}

export default Header;
