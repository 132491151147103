import React from 'react';

function Terms() {
    return (
        <div>
            <h1>Conditions d'utilisation</h1>
            <p>Voici les conditions d'utilisation de notre site...</p>
        </div>
    );
}

export default Terms; 