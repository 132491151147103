import React from 'react';

function Cookies() {
    return (
        <div>
            <h1>Politique relative à l'utilisation des cookies</h1>
            <p>Voici notre politique concernant les cookies...</p>
        </div>
    );
}

export default Cookies;
