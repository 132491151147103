import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';

emailjs.init('T-pY9-uH4qzIB7dph');

function Contact() {
    const [formData, setFormData] = useState({
        restoName: '',
        firstName: '',
        email: '',
        telephone: '+33',
        ville: '',
        pays: 'France',
        commentaire: ''
    });

    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('+33');

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch('https://restcountries.com/v3.1/all');
            const data = await response.json();
            const countryOptions = data.map(country => ({
                name: country.name.common,
                code: country.cca2,
                dialCode: country.idd.root + (country.idd.suffixes && country.idd.suffixes.length > 0 ? country.idd.suffixes[0] : '')
            }));
            setCountries(countryOptions);
        };

        fetchCountries();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCountryChange = (e) => {
        const selectedCountry = countries.find(country => country.code === e.target.value);
        setFormData({
            ...formData,
            pays: selectedCountry.name,
            telephone: selectedCountry.dialCode
        });
        setCountryCode(selectedCountry.dialCode);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const webhookURL = 'https://discord.com/api/webhooks/1308510644344983644/d49KW4O6VJnZZY6NNV7wJAzzgloji4ibBfyHsZrY926mnZHs6zuJAdXSTSLs1N3g7av8';

        const message = {
            content: `Nouveau contact:\nNom d'établissement: ${formData.restoName}\nNom et prénom: ${formData.firstName}\nEmail: ${formData.email}\nVille: ${formData.ville}\nPays: ${formData.pays}\nTéléphone: ${formData.telephone}\nCommentaire: ${formData.commentaire}`
        };

        let success = true;

        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(message)
            });

            if (!response.ok) {
                success = false;
            }

            const emailData = {
                restoName: formData.restoName,
                firstName: formData.firstName,
                email: formData.email,
                ville: formData.ville,
                pays: formData.pays,
                telephone: formData.telephone,
                commentaire: formData.commentaire
            };

            await emailjs.send('service_yuq4j4t', 'template_4lvb5zy', emailData);
        } catch (error) {
            success = false;
        }

        if (success) {
            alert('Données envoyées avec succès');
            setFormData({
                restoName: '',
                firstName: '',
                email: '',
                telephone: '',
                ville: '',
                pays: 'France',
                commentaire: ''
            });
        } else {
            alert('Problème lors de l\'envoi des données');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="contact-form">
            <h2 data-lang-key>Contact</h2>
            <div className="form-row">
                <input name="restoName" placeholder="Votre nom d'établissement*" onChange={handleChange} required data-lang-key />
                <input name="firstName" placeholder="Votre nom et prénom*" onChange={handleChange} required data-lang-key />
            </div>
            <div className="form-row">
                <select name="pays" onChange={handleCountryChange} required value={countries.find(country => country.dialCode === countryCode)?.code}>
                    {countries.map(country => (
                        <option key={country.code} value={country.code} data-lang-key>
                            {country.name} ({country.dialCode})
                        </option>
                    ))}
                </select>
                <input
                    name="telephone"
                    type="tel"
                    placeholder="Votre téléphone*"
                    value={formData.telephone}
                    onChange={handleChange}
                    required
                    data-lang-key
                />
            </div>
            <div className="form-row">
                <input name="ville" placeholder="Ville*" onChange={handleChange} required data-lang-key />
                <input name="email" type="email" placeholder="Votre email*" onChange={handleChange} required data-lang-key />
            </div>
            <div className="form-row">
                <textarea className="wide" name="commentaire" placeholder="Demande / Démo / Questions ?*" onChange={handleChange} required data-lang-key></textarea>
            </div>
            <button type="submit" className="submitBtn" data-lang-key>Envoyer</button>
        </form>
    );
};

export default Contact;
