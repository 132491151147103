import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import FAQ from './pages/FAQ';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';
import './App.css';
import translateFR from './Assets/translateFR.png';
import translateEN from './Assets/translateEN.png';
import CookieConsent from "react-cookie-consent";
import { setCookie, getCookie } from './pages/CookieManager';

function App() {
    const [language, setLanguage] = useState("fr");
    const [translateIcon, setTranslateIcon] = useState(translateEN);
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    const translateText = useCallback(async (text, targetLang) => {
        const response = await fetch(
            `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${targetLang}&dt=t&q=${encodeURIComponent(text)}`
        );
        const data = await response.json();
        return data[0][0][0];
    }, []);

    const updateLanguage = useCallback(async () => {
        const elements = document.querySelectorAll("[data-lang-key]");
        for (const el of elements) {
            const originalText = el.getAttribute("data-original-text") || el.textContent;
            const translatedText = await translateText(originalText, language);
            el.textContent = translatedText;
            el.setAttribute("data-original-text", originalText);
        }
    }, [language, translateText]);

    useEffect(() => {
        const savedLanguage = getCookie('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
            setTranslateIcon(savedLanguage === "en" ? translateFR : translateEN);
        }
        updateLanguage();
    }, [language, updateLanguage]);

    useEffect(() => {
        const userPreference = getCookie('skaneat_cookies');
        if (userPreference) {
            setCookiesAccepted(true);
        }

        if (!cookiesAccepted) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [cookiesAccepted]);

    const handleUserPreference = async (preference) => {
        await setCookie('user_preference', preference);
        await setCookie('language', language);
        setCookiesAccepted(true);
    };

    const toggleLanguage = async () => {
        const newLanguage = language === "en" ? "fr" : "en";
        setLanguage(newLanguage);
        setTranslateIcon(prevIcon => (prevIcon === translateFR ? translateEN : translateFR));
        await handleUserPreference(newLanguage);
    };

    const isCookieFreePage = () => {
        const path = window.location.pathname;
        return path === "/terms" || path === "/privacy" || path === "/cookies";
    };

    return (
        <Router>
            <div style={{ 
                filter: !cookiesAccepted && !isCookieFreePage() ? 'blur(5px)' : 'none',
                pointerEvents: !cookiesAccepted && !isCookieFreePage() ? 'none' : 'auto'
            }}>
                <Header toggleLanguage={toggleLanguage} translateIcon={translateIcon} isCookieFreePage={isCookieFreePage()}/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/cookies" element={<Cookies />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer isCookieFreePage={isCookieFreePage()}/>
            </div>
            {!cookiesAccepted && !isCookieFreePage() && (
              <CookieConsent
                  location="none"
                  buttonText={language === "fr" ? "Accepter" : "Accept"}
                  declineButtonText={language === "fr" ? "Refuser" : "Decline"}
                  cookieName="skaneat_cookies"
                  enableDeclineButton={true}
                  onAccept={() => {
                      handleUserPreference('accepted');
                      setCookiesAccepted(true);
                  }}
                  onDecline={() => {
                      handleUserPreference('preferences');
                      setCookiesAccepted(true);
                  }}
                  style={{ 
                      background: "white",
                      position: "fixed",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "450px",
                      width: "90%",
                      borderRadius: "12px",
                      padding: "20px",
                      boxShadow: "0 2px 20px rgba(0, 0, 0, 0.1)",
                      color: "#000",
                      zIndex: 10000,
                      border: "1px solid #eee",
                      display: cookiesAccepted ? 'none' : 'block'
                  }}
                  buttonStyle={{ 
                      background: "#000",
                      color: "white",
                      fontSize: "14px",
                      borderRadius: "25px",
                      padding: "8px 24px",
                      border: "none",
                      cursor: "pointer",
                      minWidth: "100px",
                      fontWeight: "500"
                  }}
                  declineButtonStyle={{
                      background: "#f5f5f5",
                      color: "#000",
                      fontSize: "14px",
                      borderRadius: "25px",
                      padding: "8px 24px",
                      border: "none",
                      cursor: "pointer",
                      minWidth: "100px",
                      fontWeight: "500"
                  }}
                  buttonWrapperStyle={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      gap: '10px',
                      padding: 0,
                      marginTop: '15px'
                  }}
                  containerStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 0,
                      margin: 0
                  }}
              >
                  <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
                          <h3 style={{ 
                              margin: '0', 
                              fontSize: '18px', 
                              fontWeight: '600' 
                          }}>
                              Cookies
                          </h3>
                          <p style={{ 
                              margin: 0, 
                              fontSize: '14px', 
                              lineHeight: '1.5',
                              color: '#666'
                          }}>
                              {language === "fr" 
                                  ? "Nous utilisons des cookies et des technologies similaires pour personnaliser le contenu, adapter et mesurer les publicités, et offrir une meilleure expérience."
                                  : "We use cookies and similar technologies to help personalize content, tailor and measure ads, and provide a better experience."}
                          </p>
                              <p style={{ 
                                  margin: 0, 
                                  fontSize: '12px', 
                                  color: '#666'
                              }}>
                                  <a href="/terms" target="_blank" rel="noopener noreferrer" style={{ color: '#000', textDecoration: 'underline' }}>Conditions d'utilisation</a> <br/>
                                  <a href="/privacy" target="_blank" rel="noopener noreferrer" style={{ color: '#000', textDecoration: 'underline' }}>Politique de confidentialité</a> <br/>
                                  <a href="/cookies" target="_blank" rel="noopener noreferrer" style={{ color: '#000', textDecoration: 'underline' }}>Politique relative à l'utilisatin des cookies</a>
                              </p>
                      </div>
                      <img 
                          src="/cookie-icon.png" 
                          alt="Cookie" 
                          style={{
                              width: '90px',
                              height: '90px',
                              objectFit: 'contain'
                          }}
                      />
                  </div>
              </CookieConsent>
            )}
        </Router>
    );
}

export default App;
