import React from 'react';

function Privacy() {
    return (
        <div>
            <h1>Politique de confidentialité</h1>
            <p>Voici notre politique de confidentialité...</p>
        </div>
    );
}

export default Privacy; 