import React from 'react';
import '../styles/Features.css';
import featureImage1 from '../Assets/feature1.svg';
import featureImage2 from '../Assets/feature2.svg';
import featureImage3 from '../Assets/feature3.svg';

function Features() {
    return (
        <div className="features-container">
            <h2 className="features-title">Fonctionnalités de SkanEat</h2>
            <p className="features-description">Découvrez comment SkanEat peut transformer votre expérience de commande.</p>

            <div className="features-list">
                <div className="feature-item">
                    <img src={featureImage1} alt="Fonctionnalité 1" className="feature-image" />
                    <h3>Fonctionnalité 1</h3>
                    <p>Une description détaillée de la fonctionnalité 1. Expliquez comment cela fonctionne et pourquoi c'est bénéfique.</p>
                </div>

                <div className="feature-item">
                    <img src={featureImage2} alt="Fonctionnalité 2" className="feature-image" />
                    <h3>Fonctionnalité 2</h3>
                    <p>Une description détaillée de la fonctionnalité 2. Expliquez comment cela fonctionne et pourquoi c'est bénéfique.</p>
                </div>

                <div className="feature-item">
                    <img src={featureImage3} alt="Fonctionnalité 3" className="feature-image" />
                    <h3>Fonctionnalité 3</h3>
                    <p>Une description détaillée de la fonctionnalité 3. Expliquez comment cela fonctionne et pourquoi c'est bénéfique.</p>
                </div>
            </div>
        </div>
    );
}

export default Features;
