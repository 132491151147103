import React from 'react';
import '../styles/Pricing.css';

function Pricing() {
    const pricing = [
        { text: "Mise en place du menu interactif via QR-Code entièrement personnalisable", basic: true, standard: true, premium: true, custom: true },
        { text: "Base de données", basic: true, standard: true, premium: true, custom: true },
        { text: "Assistance 5j/7", basic: true, standard: true, premium: true, custom: true },
        { text: "Assistance 7j/7", basic: false, standard: true, premium: true, custom: true },
        { text: "Nom de sous-domaine personnalisable", basic: true, standard: true, premium: true, custom: true },
        { text: "Création de site web individuel", basic: false, standard: true, premium: true, custom: true },
        { text: "Avis des clients", basic: false, standard: true, premium: true, custom: true },
        { text: "Gestion de réservation", basic: false, standard: true, premium: true, custom: true },
        { text: "Menu multilingue (français, anglais, italien, allemand, japonais, russe)", basic: false, standard: false, premium: true, custom: true },
        { text: "Système de suivi de commande en temps réel", basic: false, standard: false, premium: true, custom: true },
        { text: "Paiement en ligne", basic: false, standard: true, premium: true, custom: true },
        { text: "Fonctionnalités personnalisées selon vos besoins", basic: false, standard: false, premium: false, custom: true },
    ];

    return (
        <div>
            <h2 data-lang-key>Tarifs (12 mois d'engagement minimum)</h2>
            <div className="pricing-container">
                <div className="pricing-plan">
                    <h3 data-lang-key>Pack Basique</h3>
                    <p data-lang-key>À partir de 599€</p>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.basic ? "✅" : "❌"}</span>{" "}
                                <span data-lang-key>{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                    <div className="bottom-div">
                        <p data-lang-key>Puis 129€ / mois</p>
                    </div>
                </div>

                <div className="pricing-plan">
                    <h3 data-lang-key>Pack Standard</h3>
                    <p data-lang-key>À partir de 799€</p>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.standard ? "✅" : "❌"}</span>{" "}
                                <span data-lang-key>{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                    <div className="bottom-div">
                        <p data-lang-key>Puis 179€ / mois</p>
                    </div>
                </div>

                <div className="pricing-plan">
                    <h3 data-lang-key>Pack Premium</h3>
                    <p data-lang-key>À partir de 999€</p>
                    <div className="pricing-list">
                        {pricing.map((pricing, index) => (
                            <p key={index} className="pricing-item">
                                <span>{pricing.premium ? "✅" : "❌"}</span>{" "}
                                <span data-lang-key>{pricing.text}</span>
                            </p>
                        ))}
                    </div>
                    <div className="bottom-div">
                        <p data-lang-key>Puis 229€ / mois</p>
                    </div>
                </div>

                <div className="pricing-plan">
                    <h3 data-lang-key>Pack Sur Mesure</h3>
                    <p data-lang-key>Sur devis uniquement</p>
                    <div className="bottom-div">
                        <a href="/contact" className="contact-button" data-lang-key>
                            Prendre contact
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pricing;
