import Cookies from 'js-cookie';

const DISCORD_WEBHOOK_URL = "https://discord.com/api/webhooks/1313255551005888612/oQHfQNnadAQU6eoZqfBDRnmmdcro_5rHGmWswuQWohkrrkJuQS3owuNwZWqvwppiEe5j";

const detectDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    return isMobile ? "📱 Mobile" : "💻 PC";
};

const getLocation = async () => {
    try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        return {
            city: data.city,
            country: data.country_name
        };
    } catch (error) {
        return {
            city: "Inconnue",
            country: "Inconnu"
        };
    }
};

const sendToDiscord = async (data) => {
    try {
        const location = await getLocation();
        const device = detectDevice();
        const timeInfo = {
            local: new Date().toLocaleString('fr-FR'),
        };

        const payload = {
            content: "✅ Nouveau consentement cookies SkanEat!",
            embeds: [{
                title: "Détails du consentement",
                color: 5763719,
                fields: [
                    {
                        name: "📱 Appareil",
                        value: device,
                        inline: true
                    },
                    {
                        name: "🌍 Localisation",
                        value: `${location.city}, ${location.country}`,
                        inline: true
                    },
                    {
                        name: "⏰ Date",
                        value: timeInfo.local,
                        inline: true
                    }
                ],
                footer: {
                    text: "SkanEat Analytics"
                },
                timestamp: new Date().toISOString()
            }]
        };

        const response = await fetch(DISCORD_WEBHOOK_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });

        return response.ok;
    } catch (error) {
        return false;
    }
};

const setCookie = async (name, value, options = {}) => {
    try {
        Cookies.set(name, value, { ...options, secure: true });

        if (name === 'user_preference' && value === 'accepted') {
            await sendToDiscord({
                language: Cookies.get('language') || 'fr'
            });
        }
        
        return true;
    } catch (error) {
        return false;
    }
};

const getCookie = (name) => {
    try {
        return Cookies.get(name);
    } catch {
        return null;
    }
};

const removeCookie = (name) => {
    try {
        Cookies.remove(name);
        return true;
    } catch {
        return false;
    }
};

const checkCookieConsent = () => {
    return getCookie('user_preference') === 'accepted';
};

export { setCookie, getCookie, removeCookie, checkCookieConsent };

const CookieManager = {
    setCookie,
    getCookie,
    removeCookie,
    checkCookieConsent
};

export default CookieManager;
