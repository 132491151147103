import React, { useState, useEffect } from 'react';
import '../styles/FAQ.css';

function FAQ() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const faqs = [
        {
            question: "Le QR code qu'est-ce que c'est ?",
            answer: "Le QR code est un tag lisible par les téléphones portables et les tablettes. Sa lecture se fait en ouvrant l’appareil photo du mobile ce qui permet d’accéder directement à une page web présentant la carte de votre restaurant ou bar !"
        },
        {
            question: "La puce NFC qu'est-ce que c'est ?",
            answer: "La puce NFC est une petite puce lisible par les téléphones portables IOS comme Android. Sa lecture se fait en approchant le smartphone près de la puce ce qui permet d’accéder directement à une page web présentant la carte de votre restaurant ou bar !"
        },
        {
            question: "Passer commande depuis son mobile ?",
            answer: "Votre menu QR code ou NFC peut offrir à vos clients la possibilité d’envoyer leurs commandes directement sur votre logiciel de caisse ou sur une tablette standardisée."
        },
        {
            question: "Comment créer un menu QR Code ?",
            answer: "Prenez rendez-vous avec notre équipe ou demandez un devis en ligne pour votre établissement. Nous assurons le déplacement sur place pour mettre en place ensemble votre formulaire de commande en ligne."
        },
        {
            question: "Afficher les allergènes ?",
            answer: "Vous pouvez sélectionner les allergènes associés à chaque plat et les indiquer sur votre menu. Ainsi vos clients sont mieux informés quand ils lisent votre menu."
        },
        {
            question: "Adapter les prix ?",
            answer: "Il est possible d'afficher des prix différents en fonction des horaires que vous définissez (midi et soir, semaine et weekend)."
        },
        {
            question: "Mettre à jour mon menu ?",
            answer: "Vous pouvez mettre à jour votre menu en nous contactant. Nous nous occupons personnellement de modifier votre carte en temps réel."
        },
        {
            question: "Personnaliser la charte graphique ?",
            answer: "La charte graphique est entièrement personnalisable : logo, page d'accueil, arrière plan, couleur et police de caractère. Nous nous occupons d'intégrer votre charte graphique à la mise en place de votre menu."
        }
    ];

    const filteredFaqs = faqs.filter(faq =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openPopup = (faq) => setSelectedQuestion(faq);
    const closePopup = () => setSelectedQuestion(null);

    useEffect(() => {
        document.body.classList.toggle('no-scroll', !!selectedQuestion);
    }, [selectedQuestion]);

    const highlightText = (text, searchTerm) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.split(regex).map((part, index) => 
            regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
    };

    return (
        <div className="faq-section">
            <h2 className="faq-title" data-lang-key>FAQ</h2>
            <input
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <div className="faq-container">
                {filteredFaqs.length > 0 ? (
                    filteredFaqs.map((faq, index) => (
                        <div className="faq-item" key={index} onClick={() => openPopup(faq)}>
                            <h3 data-lang-key>{highlightText(faq.question, searchTerm)}</h3>
                        </div>
                    ))
                ) : (
                    <p className="no-results" data-lang-key>Aucun résultat trouvé pour votre recherche.</p>
                )}
            </div>

            {selectedQuestion && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <h3 data-lang-key>{highlightText(selectedQuestion.question, searchTerm)}</h3>
                        <p data-lang-key>{highlightText(selectedQuestion.answer, searchTerm)}</p>
                        <button className="close-btn" data-lang-key onClick={closePopup}>Fermer</button>
                    </div>
                </div>
            )}

            <p className="contact-message">
                <a href="/contact" target='_blank' className="contact-link" data-lang-key>Pour plus de renseignements, contactez-nous</a>.
            </p>
        </div>
    );
}

export default FAQ;
