import React from 'react';
import '../styles/Home.css';
import scanImage from '../Assets/scan.svg';
import orderImage from '../Assets/order.svg';
import cookingImage from '../Assets/cooking.svg';
import eatImage from '../Assets/eat.svg';
import messageImage from '../Assets/message.svg';
import timeImage from '../Assets/time.svg';
import phoneImage from '../Assets/phone.svg';

function Home() {
    return (
        <div className="home-container">
            <header className="hero-section">
                <h1 className="hero-title">Bienvenue chez SkanEat</h1>
                <p className="hero-subtitle">Transformez l'expérience de commande de vos clients avec notre solution QR Code et NFC. Profitez d'une expérience culinaire sans précédent, où chaque plat est à portée de main !</p>
                <p className="hero-description">Rejoignez-nous pour simplifier le processus de commande et offrir à vos clients un service rapide et efficace, tout en réduisant les erreurs de commande.</p>
            </header>

            <section className="features-section">
                <h2 className="section-title">Comment ça marche ?</h2>
                <div className="features-container">
                    <div className="feature">
                        <img src={scanImage} alt="Scan QR Code" className="feature-image" />
                        <h3>Étape 1 - Scan du QR Code</h3>
                        <p>Vos clients peuvent rapidement accéder à votre menu en scannant le QR code ou en s'approchant de la puce NFC.</p>
                    </div>
                    <div className="feature">
                        <img src={orderImage} alt="Consultation et commande" className="feature-image" />
                        <h3>Étape 2 - Consultation et commande</h3>
                        <p>Ils parcourent votre menu et préparent leur commande en un clin d'œil.</p>
                    </div>
                    <div className="feature">
                        <img src={cookingImage} alt="Commande en cuisine" className="feature-image" />
                        <h3>Étape 3 - La commande arrive en cuisine</h3>
                        <p>Chaque commande est automatiquement transmise à votre logiciel en cuisine.</p>
                    </div>
                    <div className="feature">
                        <img src={eatImage} alt="Commande à la table" className="feature-image" />
                        <h3>Étape 4 - La commande arrive à la table</h3>
                        <p>Les serveurs apportent directement les commandes à la table des clients.</p>
                    </div>
                </div>
            </section>

            <section className="why-choose-section">
                <h2 className="section-title">Pourquoi choisir SkanEat ?</h2>
                <div className="why-choose-container">
                    <div className="why-choose-item">
                        <img src={phoneImage} alt="Accès sans contact" className="why-image" />
                        <h3>Accès avec un menu sans contact</h3>
                        <p>Utilisez n'importe quel téléphone pour scanner le QR code ou la puce NFC.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={messageImage} alt="Satisfaction Client" className="why-image" />
                        <h3>Satisfaction Client</h3>
                        <p>Analysez les retours de vos clients grâce à notre questionnaire de satisfaction.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={timeImage} alt="Gain de temps" className="why-image" />
                        <h3>Gagnez plusieurs minutes à chaque service</h3>
                        <p>Les commandes sont directement transférées à votre logiciel de caisse.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
