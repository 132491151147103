import React from 'react';
import '../styles/Footer.css';

function Footer({ isCookieFreePage }) {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links" style={{ display: isCookieFreePage ? 'none' : 'block' }}>
                    <h4>Liens utiles</h4>
                    <ul>
                        <li><a href="/terms" target="_blank" rel="noopener noreferrer">Conditions d'utilisation</a></li>
                        <li><a href="/privacy" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a></li>
                        <li><a href="/cookies" target="_blank" rel="noopener noreferrer">Politique relative à l'utilisation des cookies</a></li>
                    </ul>
                </div>
                <div className="social-media" style={{ display: isCookieFreePage ? 'none' : 'block' }}>
                    <h4>Réseaux sociaux</h4>
                    <ul>
                        <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                        <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                    </ul>
                </div>
            </div>
            <p data-lang-key>&copy; 2024 SkanEat. Tous droits réservés.</p>
        </footer>
    );
}

export default Footer;
